@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

.mtb-avatar--theme {
    /* DO NOT USE --color variables directly in code, they should only be referenced in this file in the Semantic variables */
    /* Colors */
    --mtb-avatar-color-primary-gold: #feb710;

    --mtb-avatar-color-secondary-beige: #fcd888;
    --mtb-avatar-color-secondary-bronze: #9b6d00;
    --mtb-avatar-color-secondary-coffee: #664800;

    --mtb-avatar-color-neutral-black: #000000;
    --mtb-avatar-color-neutral-midnight: #25252E;
    --mtb-avatar-color-neutral-graphite: #373737;
    --mtb-avatar-color-neutral-smoke: #797979;
    --mtb-avatar-color-neutral-fog: #9c9c9c;
    --mtb-avatar-color-neutral-mist: #e8edee;
    --mtb-avatar-color-neutral-white: #ffffff;

    --mtb-avatar-color-accent-crimson: #8d0909;
    --mtb-avatar-color-accent-scarlet: #e02b2b;
    --mtb-avatar-color-accent-rose: #f99c9c;
    --mtb-avatar-color-accent-blush: #fccece;
    --mtb-avatar-color-accent-forest: #1a6234;
    --mtb-avatar-color-accent-grass: #34c369;
    --mtb-avatar-color-accent-mint: #97e3b3;
    --mtb-avatar-color-accent-foam: #cbf1d9;

    /* Semantic */
    --mtb-avatar-font-family-primary: 'Space Grotesk', sans-serif;

    --mtb-avatar-title-primary-text: var(--mtb-avatar-color-neutral-white);

    --mtb-avatar-background-default: var(--mtb-avatar-color-neutral-midnight);

    --mtb-avatar-button-primary-fill: var(--mtb-avatar-color-primary-gold);
    --mtb-avatar-button-primary-fill-active: var(--mtb-avatar-color-primary-gold);
    --mtb-avatar-button-primary-border: var(--mtb-avatar-color-primary-gold);
    --mtb-avatar-button-primary-border-active: var(--mtb-avatar-color-primary-gold);
    --mtb-avatar-button-primary-text: var(--mtb-avatar-color-neutral-black);
    --mtb-avatar-button-primary-text-active: var(--mtb-avatar-color-neutral-black);

    --mtb-avatar-button-secondary-fill: var(--mtb-avatar-color-secondary-bronze);
    --mtb-avatar-button-secondary-fill-active: var(--mtb-avatar-color-secondary-bronze);
    --mtb-avatar-button-secondary-border: var(--mtb-avatar-color-secondary-bronze);
    --mtb-avatar-button-secondary-border-active: var(--mtb-avatar-color-secondary-bronze);
    --mtb-avatar-button-secondary-text: var(--mtb-avatar-color-neutral-midnight);
    --mtb-avatar-button-secondary-text-active: var(--mtb-avatar-color-neutral-midnight);

    --mtb-avatar-button-menu-icon: #2d2d2dcc;
    --mtb-avatar-button-menu-icon-active: #2d2d2d;
    --mtb-avatar-button-menu-fill: #ffffff44;
    --mtb-avatar-button-menu-fill-active: #ffffff;
    --mtb-avatar-button-menu-shadow: #00000088;
    --mtb-avatar-button-menu-toggle-icon: #000000;

    --mtb-avatar-button-option-icon: #2d2d2dcc;
    --mtb-avatar-button-option-icon-active: #2d2d2d;
    --mtb-avatar-button-option-fill: var(--mtb-avatar-color-neutral-fog);
    --mtb-avatar-button-option-fill-active: var(--mtb-avatar-color-neutral-fog);
    --mtb-avatar-button-option-shadow: #00000088;

    --mtb-avatar-border-color-default: var(--mtb-avatar-color-neutral-white);
    --mtb-avatar-border-color-saved: var(--mtb-avatar-color-secondary-bronze);
    --mtb-avatar-border-color-staged: var(--mtb-avatar-color-primary-gold);

    --mtb-avatar-loading-gradient: linear-gradient(
        to left,
        var(--mtb-avatar-color-primary-gold),
        var(--mtb-avatar-color-secondary-coffee),
        var(--mtb-avatar-color-secondary-coffee),
        var(--mtb-avatar-color-primary-gold)
    );
}
